import { useTheme } from '@material-ui/styles';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import map from 'lodash/map';

export const UsersPanel = ({ venue }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <div style={{ margin: theme.spacing(2) }}>
      {map(venue.users, (u) => (
        <Grid container spacing={2}>
          <Grid item="xs">
            <Button variant="contained" href={`/admin/users/${u.id}`}>
              {u.firstName} {u.lastName} ({u.email}) - {u.role}
            </Button>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
