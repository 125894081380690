import { Button, Grid, Typography } from '@material-ui/core';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { openInNewTab } from '../../../utils';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { useTheme } from '@material-ui/styles';
import { moneyPrint } from '@pv/common/utils';

export const SubscriptionItem = ({ subscription, header }) => {
  const theme = useTheme();
  const stripeLink =
    subscription.stripeSubscriptionId || subscription.stripeScheduleId;

  return (
    <Grid>
      {header && (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
            width: '50%',
          }}
        >
          <Typography variant="h4">{header}</Typography>
        </Grid>
      )}
      <Grid container direction="row">
        <Typography paragraph={false}>
          Tier: <strong>{subscription.planName}</strong>
          <br />
          Subscription Plan ID: {subscription.id}
          <br />
          Billing: {startCase(subscription.billingInterval)}
          <br />
          Monthly Rate: {moneyPrint((subscription.monthlyRate || 0) / 100)} (
          <em>
            {moneyPrint(((subscription.monthlyRate || 0) / 100) * 12)} / yr)
          </em>
          <br />
          Start: {moment(subscription.startDate).format('MMMM DD, YYYY')}
          {subscription.endDate && (
            <>
              <br />
              End: {moment(subscription.endDate).format('MMMM DD, YYYY')}
            </>
          )}
        </Typography>
        {stripeLink && (
          <Button
            size="small"
            style={{ marginLeft: theme.spacing(1) }}
            onClick={openInNewTab({
              url: `https://dashboard.stripe.com/subscriptions/${stripeLink}`,
            })}
          >
            <LaunchIcon fontSize="small" />
          </Button>
        )}
      </Grid>
      <br />
    </Grid>
  );
};
