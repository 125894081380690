import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Box, FormControlLabel, Checkbox } from '@mui/material';
import { AdminVenue } from '@pv/common/graphql/types';

const adminUpdateVenueFeatureFlagsMutation = gql`
  mutation AdminUpdateVenueFeatureFlags($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        featureFlags
      }
    }
  }
`;

interface FeatureFlagsPanelProps {
  venue: AdminVenue;
}

export const FeatureFlagsPanel: React.FC<FeatureFlagsPanelProps> = ({
  venue,
}) => {
  const [updateVenue] = useMutation(adminUpdateVenueFeatureFlagsMutation);
  const [featureFlags, setFeatureFlags] = React.useState(venue.featureFlags);

  const formatFlagName = (flag: string) => {
    const formattedFlagName = flag.replace(/([A-Z])/g, ' $1');
    return (
      formattedFlagName.charAt(0).toUpperCase() + formattedFlagName.slice(1)
    );
  };

  return (
    <Box>
      {Object.entries(venue.featureFlags).map(([flag, value]) => (
        <Box key={flag}>
          <FormControlLabel
            label={formatFlagName(flag)}
            control={
              <Checkbox
                checked={!!value}
                color="primary"
                onChange={() => {
                  setFeatureFlags({
                    ...featureFlags,
                    [flag]: !value,
                  });
                  updateVenue({
                    variables: {
                      input: {
                        id: venue.id,
                        featureFlags: {
                          [flag]: !value,
                        },
                      },
                    },
                  });
                }}
              />
            }
          />
        </Box>
      ))}
    </Box>
  );
};
