import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useMutation } from '@pv/common/hooks';
import {
  Fields,
  PVLoadingButton,
  SidebarHeader,
  SidebarDrawer,
} from '@pv/common/components';
import { moneyPrintRound } from '@pv/common/utils';
import { sortBy } from 'lodash';

const useStyles = makeStyles(() => ({
  hiddenFileInput: {
    position: 'fixed',
    top: '-100em',
  },
}));

const tableFieldsOnVenue = gql`
  fragment TableFieldsOnVenue on AdminVenue {
    name
    slug
    email
    timezone
    eventCount
    settings
    planName
    featureFlags
    cardApplicationFeeRate
    achApplicationFeeRate
    monthlyRate
    helloLeadFormPath
    basicFeaturesEnabled
    proFeaturesEnabled
    premiumFeaturesEnabled
    customerStartedAt
    suspicious
    billingInterval
    achEnabled
    spaceCount
    createdAt
    expressBookSettings {
      id
      enabled
    }
    stripeAccount {
      id
    }
    websiteUrl
    organization {
      id
      name
    }
  }
`;

export const adminUpdateVenueMutation = () => gql`
  mutation AdminUpdateVenue($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        ...TableFieldsOnVenue
      }
      errors {
        message
      }
    }
  }
  ${tableFieldsOnVenue}
`;

const createVenueMutation = () => gql`
  mutation CreateReservation($input: CreateVenueInput!) {
    createVenue(input: $input) {
      venue {
        id
        venueMemberships {
          id
          user {
            id
            magicLoginLink
          }
        }
      }
      errors {
        message
      }
    }
  }
`;

export const planDefinitionsQuery = gql`
  query AdminPlanDefinitions {
    adminPlanDefinitions {
      id
      tier
      planName
      billingInterval
      monthlyRate
      achApplicationFeeRate
      cardApplicationFeeRate
      stripeProductId
      stripePriceId
      active
    }
  }
`;

export const VenueDrawer = ({
  organization,
  venue,
  open,
  onClose,
  refetchVenues,
}) => {
  const classes = useStyles();

  const elementId = useRef(uuidv4());
  const [name, setName] = useState('');
  const [numberOfTrialDays, setNumberOfTrialDays] = useState(14);
  const [selectedPlanDefinitionId, setSelectedPlanDefinitionId] = useState('');
  const [achEnabled, setAchEnabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [forceValidate, setForceValidate] = useState(false);

  const { data, loading: plansLoading } = useQuery(planDefinitionsQuery);
  const availablePlans = sortBy(
    data?.adminPlanDefinitions || [],
    'monthlyRate'
  );
  const trialDefinitionId = availablePlans.find((p) => p.tier === 'trial')?.id;

  const selectPlanOptions = availablePlans.map((plan) => [
    plan.id,
    `${plan.planName} → ${
      plan.billingInterval === 'annual'
        ? `${moneyPrintRound((plan.monthlyRate * 12) / 100)}/yr`
        : `${moneyPrintRound(plan.monthlyRate / 100) || '$0'}/mo`
    } `,
    false,
  ]);

  useEffect(() => {
    if (open) {
      setName(venue?.name || '');
      setSelectedPlanDefinitionId(
        venue?.subscription?.planDefinitionId || trialDefinitionId
      );
      setAchEnabled(venue?.achEnabled || false);
    }
  }, [open, venue, trialDefinitionId]);

  const [updateVenue, { loading: updateLoading }] = useMutation(
    adminUpdateVenueMutation(),
    {
      onNoErrorsCompleted: () => {
        enqueueSnackbar('Updated Venue', { variant: 'success' });
        onClose();
        if (afterConfirm) {
          afterConfirm();
        }
      },
    }
  );

  const [createVenue, { loading: createLoading }] = useMutation(
    createVenueMutation(),
    {
      onNoErrorsCompleted: () => {
        enqueueSnackbar('Created Venue', { variant: 'success' });
        onClose();
        if (afterConfirm) {
          afterConfirm();
        }
      },
    }
  );

  const loading = updateLoading || createLoading || plansLoading;

  const onSave = () => {
    if (!name) {
      setForceValidate(true);
      return;
    }

    const input = {
      name,
      numberOfTrialDays,
      planDefinitionId: selectedPlanDefinitionId,
      achEnabled,
    };
    const variables = { input };
    if (venue) {
      input.id = venue.id;
      updateVenue({ variables });
    } else {
      input.organizationId = organization.id;
      createVenue({ variables });
    }
  };

  const afterConfirm = () => {
    onClose();
    if (refetchVenues) {
      refetchVenues();
    }
  };

  const onChangeUploadFile = async (e) => {
    const url = `/venues/${venue?.id}/upload_events`;
    const csv = e.target.files[0];
    const formData = new FormData();
    formData.append('csv', csv);
    formData.append('id', venue.id);
    setUploading(true);
    const r = await axios.post(url, formData);
    if (r.data.ok) {
      enqueueSnackbar('Uploaded Events', { variant: 'success' });
    } else {
      enqueueSnackbar(r.data.error, { variant: 'error' });
    }
    setUploading(false);
  };

  return (
    <SidebarDrawer open={open} onClose={onClose}>
      <>
        <div className="venue-drawer">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <SidebarHeader
                  title={
                    venue
                      ? `Edit ${venue?.name}`
                      : `New Venue on ${organization?.name}`
                  }
                />
                <Button
                  disabled={loading}
                  onClick={onSave}
                  size="small"
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Fields.PvTextField
                required
                label="Venue Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                name="name"
                forceValidate={forceValidate}
              />
            </Grid>
            <Grid item xs={12}>
              <Fields.PvDropDown
                required
                label="Plan"
                name="plan"
                value={selectedPlanDefinitionId}
                onChange={(e) => setSelectedPlanDefinitionId(e.target.value)}
                vals={selectPlanOptions}
              />
            </Grid>
            <Grid item xs={12}>
              {trialDefinitionId === selectedPlanDefinitionId && (
                <Fields.PvIntegerField
                  name="trialDays"
                  allowNegative={false}
                  value={numberOfTrialDays}
                  onChange={(e) => setNumberOfTrialDays(e.target.value)}
                  label="Number of trial days (starts today)"
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    className="achEnabled"
                    name="achEnabled"
                    checked={achEnabled}
                    onChange={(e) => setAchEnabled(e.target.checked)}
                  />
                }
                label="ACH Enabled"
              />
            </Grid>
            {venue && (
              <Grid item xs={12}>
                <Button
                  href={`/venues/${venue?.id}/download_events`}
                  fullWidth
                  variant="outlined"
                >
                  Download Events
                </Button>
              </Grid>
            )}
            {venue && (
              <Grid item xs={12}>
                <div>
                  <div>
                    <label htmlFor={elementId.current}>
                      <PVLoadingButton
                        forceLoading={uploading}
                        fullWidth
                        variant="outlined"
                        component="span"
                      >
                        Upload Events CSV
                      </PVLoadingButton>
                    </label>
                    <input
                      id={elementId.current}
                      onChange={onChangeUploadFile}
                      type="file"
                      className={classes.hiddenFileInput}
                      accept=".csv"
                    />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </>
    </SidebarDrawer>
  );
};

export default VenueDrawer;
