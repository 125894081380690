import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useMutation } from '@pv/common/hooks';
import {
  Button,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Typography,
} from '@mui/material';
import { Check, X } from 'react-feather';

import { enqueueSnackbar } from 'notistack';
import { marketplaceHost as getMarketplaceHost } from '@pv/common/api';

const pvEnv = process.env.REACT_APP_PV_ENV;
const marketplaceHost = getMarketplaceHost(pvEnv || '');

const adminVenueMarketplaceQuery = gql`
  query adminVenueMarketplaceQuery($id: ID!) {
    adminVenue(id: $id) {
      id
      name
      slug
      suspicious
      skipSpamCheck
      marketplaceRank
      profileBanner
      address {
        id
        latitude
        longitude
      }
      images {
        id
        url
      }
      menu {
        id
        menuItems {
          id
          image
        }
      }
      subscription {
        id
        status
      }
      spaces {
        id
        name
        description
        displayInDirectory
        images {
          id
          url
        }
      }
    }
  }
`;

const adminUpdateVenueListingMutation = gql`
  mutation adminUpdateVenueListingMutation($input: AdminUpdateVenueInput!) {
    adminUpdateVenue(input: $input) {
      adminVenue {
        id
        marketplaceRank
      }
    }
  }
`;

const GreenCheck = () => <Check color="green" />;
const RedX = () => <X color="red" />;

interface Props {
  venueId?: String;
}

export const MarketplacePanel = ({ venueId }: Props) => {
  const [venue, setVenue] = React.useState<any>(null);

  const { data, loading, error } = useQuery(adminVenueMarketplaceQuery, {
    variables: { id: venueId },
    skip: !venueId,
    onCompleted: (data) => {
      setVenue(data.adminVenue);
    },
  });

  useEffect(() => {
    if (data) {
      setVenue(data.adminVenue);
    }
  }, [data]);

  const MARKETPLACE_LISTABILITY_STATUSES = {
    removed: -2,
    not_ready: -1,
    pending_approval: 0,
    approved: 1,
  };

  const [updateVenueListing] = useMutation(adminUpdateVenueListingMutation, {
    onCompleted: (data) => {
      enqueueSnackbar('Venue updated', { variant: 'success' });
    },
    onSomeErrorsCompleted: (errors) => {
      enqueueSnackbar('Error encountered while updating venue', {
        variant: 'error',
      });
    },
  });

  const removeListing = () => {
    updateVenueListing({
      variables: {
        input: {
          id: venueId,
          marketplaceRank: MARKETPLACE_LISTABILITY_STATUSES.removed,
        },
      },
    });
  };

  const approveListing = () => {
    updateVenueListing({
      variables: {
        input: {
          id: venueId,
          marketplaceRank: MARKETPLACE_LISTABILITY_STATUSES.approved,
        },
      },
    });
  };

  if (loading || !venue) {
    return null;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const listingStatus = venue.marketplaceRank;
  const listingStatusDescriptions = {
    [MARKETPLACE_LISTABILITY_STATUSES.removed]: 'Removed',
    [MARKETPLACE_LISTABILITY_STATUSES.not_ready]: 'Profile Incomplete',
    [MARKETPLACE_LISTABILITY_STATUSES.pending_approval]: 'Pending Approval',
    [MARKETPLACE_LISTABILITY_STATUSES.approved]: 'Approved',
  };
  const listingStatusDescription =
    listingStatusDescriptions[listingStatus] || 'Unknown';

  return (
    <Stack spacing={2} sx={{ padding: '24px' }}>
      <Typography variant="h2">
        Marketplace Search Status: {listingStatusDescription}
      </Typography>

      <Stack direction="row" gap={'10px'}>
        <Button
          href={`${marketplaceHost}/venues/${venue.slug}`}
          target="_blank"
          variant="outlined"
          color="primary"
        >
          View Venue Profile
        </Button>
        {listingStatus !== MARKETPLACE_LISTABILITY_STATUSES.approved && (
          <Button variant="contained" color="primary" onClick={approveListing}>
            Approve Listing
          </Button>
        )}

        {listingStatus !== MARKETPLACE_LISTABILITY_STATUSES.removed && (
          <Button variant="contained" color="secondary" onClick={removeListing}>
            Remove Listing
          </Button>
        )}
      </Stack>

      <TableContainer>
        <Table sx={{ width: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Profile Requirement</TableCell>
              <TableCell>Complete?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Profile Banner Image</Typography>
              </TableCell>
              <TableCell>
                {venue.profileBanner?.url ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Venue Profile Images</Typography>
              </TableCell>
              <TableCell>
                {venue.images[0] ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Profile Description</Typography>
              </TableCell>
              <TableCell>
                {venue.description ? <GreenCheck /> : <RedX />}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Has a logo</Typography>
              </TableCell>
              <TableCell>{venue.logo ? <GreenCheck /> : <RedX />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Mappable address</Typography>
              </TableCell>
              <TableCell>
                {venue.address?.latitude && venue.address?.longitude ? (
                  <GreenCheck />
                ) : (
                  <RedX />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Subscription Active</Typography>
              </TableCell>
              <TableCell>
                {venue.subscription?.status === 'active' ? (
                  <GreenCheck />
                ) : (
                  <RedX />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Not a spammer</Typography>
              </TableCell>
              <TableCell>
                {venue.skipSpamCheck || !venue.suspicious ? (
                  <GreenCheck />
                ) : (
                  <RedX />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  Number of listable spaces with images and descriptions
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {venue.spaces.filter(
                    (s: any) =>
                      !!s.images[0] && s.displayInDirectory && !!s.description
                  ).count || 0}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Number of menu items with images</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {venue.menu.menuItems.filter((mi: any) => !!mi.image).count ||
                    0}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
